import {
    HOME_ROUTE,
    LOGIN_ROUTE,
    REGISTRATION_ROUTE,
    PLUGINS_ROUTE, SERVERS_ROUTE, SERVER_PLUGINS_ROUTE, CREATE_SERVER
} from "./utils/consts";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import Plugins from "./pages/Plugins";
import Servers from "./pages/Servers";
import ServerPlugins from "./pages/ServerPlugins";
import CreateServer from "./pages/server/CreateServer";

export const authRoutes = [
    {
        path: PLUGINS_ROUTE,
        Component: Plugins
    },
    {
        path: SERVERS_ROUTE,
        Component: Servers
    },
    {
        path: SERVER_PLUGINS_ROUTE + '/:id',
        Component: ServerPlugins
    },
    {
        path: CREATE_SERVER,
        Component: CreateServer
    }
];

export const publicRoutes = [
    {
        path: HOME_ROUTE,
        Component: Home
    },
    {
        path: LOGIN_ROUTE,
        Component: Login
    },
    {
        path: REGISTRATION_ROUTE,
        Component: Registration
    }
];