import {$authHost, $host} from ".";

export const indexServers = async () => {
    const {data} = await $authHost.get('api/v1/servers.php', {});
    return data;
};

export const getPlugins = async (serverId) => {
    const {data} = await $authHost.get('api/v1/serverPlugins.php/' + serverId, {});
    return data;
};

export const updatePlugin = async (serverId, pluginId) => {
    const {data} = await $authHost.get('api/v1/updatePlugin.php/' + serverId + '/' + pluginId, {});
    return data;
};

export const createServer = async (server_name, server_desc, server_site, server_logo, server_size, server_seed,
                                                     server_play, server_port, query_port, rcon_port, rcon_passwd,
                                                     server_ip, server_login, server_passwd, ftp_login, ftp_passwd, oxide_pwd) => {
    const {data} = await $authHost.post('api/v1/createServer.php', {server_name, server_desc, server_site, server_logo, server_size, server_seed,
                                                                                server_play, server_port, query_port, rcon_port, rcon_passwd,
                                                                                server_ip, server_login, server_passwd, ftp_login, ftp_passwd, oxide_pwd},{withCredentials: true});
    return data;
};

export const syncServer = async (serverId) => {
    const {data} = await $authHost.get('api/v1/syncServer.php/' + serverId, {});
    return data;
};

export const jobServer = async (server_id, job) => {
    const {data} = await $authHost.post('api/v1/jobServer.php', {server_id, job},{withCredentials: true});
    return data;
};