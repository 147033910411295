import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getPlugins, updatePlugin} from "../http/ServerAPI";
import {indexPlugins} from "../http/PluginAPI";
import {observer} from "mobx-react-lite";
import {
    alpha,
    Checkbox, Modal,
    Paper,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    TableSortLabel
} from "@mui/material";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {Close, CloudUpload, Done, Settings} from "@mui/icons-material";
import {SERVER_PLUGINS_ROUTE} from "../utils/consts";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #555',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
};

function DeleteIcon() {
    return null;
}

function FilterListIcon() {
    return null;
}

const ServerPlugins = observer(() => {
    const {id} = useParams();
    const [ftpPlugins, setFtpPlugins] = useState({});
    const [umodPlugins, setUmodPlugins] = useState({});
    const [tablePlugins, setTablePlugins] = useState({});
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(9);
    const [ind, setInd] = useState(0);
    const headCells = [
        {
            id: 'plugin_id',
            numeric: true,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Наименование',
        },
        {
            id: 'version_installed',
            numeric: false,
            disablePadding: false,
            label: 'Версия на сервере',
        },
        {
            id: 'date',
            numeric: false,
            disablePadding: false,
            label: 'Дата',
        },
        {
            id: 'size',
            numeric: false,
            disablePadding: false,
            label: 'Объем',
        },
        {
            id: 'provider',
            numeric: false,
            disablePadding: false,
            label: 'Источник',
        },
        {
            id: 'version_web',
            numeric: false,
            disablePadding: false,
            label: 'Версия источника',
        },
        {
            id: 'perms',
            numeric: false,
            disablePadding: false,
            label: 'Права',
        }
    ];


    useEffect(() => {
        getPlugins(id).then(data => {
            setFtpPlugins(data);
        }).catch(e => {
            console.log(e);
        });
        indexPlugins().then(data => {
            setUmodPlugins(data);
        }).catch(e => {
            console.log(e);
        });
    }, []);

    useEffect(() => {
        if(Object.values(ftpPlugins).length > 0 && umodPlugins.length > 0) {
            const merge = Object.values(ftpPlugins).map((item, index) =>
            ({
                "plugin_id": item.plugin_id,
                "name": item.name,
                "date": item.date,
                "size": item.size,
                "provider": umodPlugins.filter(it => it.plugin_name.indexOf(item.name) !== -1),
                "perms": item.perms,
                "version_installed": item.version_installed
            })
            );
            setTablePlugins(merge);
            console.log(merge);
        }
    }, [ftpPlugins, umodPlugins]);

    useEffect(() => {
        //обновление страницы
    }, [tablePlugins]);

    useEffect(() => {
        //обновление страницы
    }, [ind]);

    function handleUpdatePlugin(pluginId) {
        //id
        updatePlugin(id, pluginId).then(data => {
            console.log('UpdatePlugin ' + data);
            getPlugins(id).then(data => {
                setFtpPlugins(data);
            }).catch(e => {
                console.log(e);
            });
        }).catch(e => {
            console.log(e);
        });

    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell
                        sortDirection={false}
                    >
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    function EnhancedTableToolbar(props) {
        const { numSelected } = props;

        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Плагины
                    </Typography>
                )}

                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Filter list">
                        <IconButton>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Toolbar>
        );
    }

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = tablePlugins.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tablePlugins.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(Object.values(tablePlugins), getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, tablePlugins],
    );

    return(
        <>
            <Container maxWidth="xl">
                <Box sx={{ width: '100%', marginTop: 3 }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={tablePlugins.length}
                                />
                                <TableBody>
                                    {tablePlugins && visibleRows.map((row, index) => {
                                        const isItemSelected = isSelected(row.name);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.name}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        onClick={(event) => handleClick(event, row.name)}
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                    align="right"
                                                >
                                                    {row.plugin_id}
                                                </TableCell>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left"
                                                           style={{'color': row.provider.length > 0 && row.provider[0].version !== row.version_installed ? 'yellow' : null}}>{row.version_installed}</TableCell>
                                                <TableCell align="left">{row.date}</TableCell>
                                                <TableCell align="left">{row.size}</TableCell>
                                                <TableCell align="left">{row.provider.length > 0 ? row.provider[0].provider : <Close />}</TableCell>
                                                <TableCell align="left">{row.provider.length > 0 ? row.provider[0].version : null}</TableCell>
                                                <TableCell align="left">{row.perms}</TableCell>
                                                <TableCell align="center">
                                                    {row.provider.length > 0 && row.provider[0].status ? <CloudUpload
                                                        color="action"
                                                        style={{'cursor': 'pointer'}}
                                                        onClick={() => handleUpdatePlugin(row.plugin_id)}
                                                    /> : null}

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (dense ? 33 : 53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[9, 18, 36]}
                            labelRowsPerPage={"Записей на странице"}
                            component="div"
                            count={tablePlugins.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            </Container>
        </>
    );
});

export default ServerPlugins;