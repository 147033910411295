import {$authHost, $host} from ".";

export const indexPlugins = async () => {
    const {data} = await $authHost.get('api/v1/plugins.php', {});
    return data;
};

export const updatePlugin = async (plugin_id) => {
    const {data} = await $authHost.post('api/v1/plugins.php/' + plugin_id, {},{withCredentials: true});
    return data;
};

export const createPlugin = async (plugin) => {
    const {data} = await $authHost.post('api/v1/createPlugin.php', plugin,{withCredentials: true});
    return data;
};

export const statusPlugin = async (plugin_id, status) => {
    const {data} = await $authHost.post('api/v1/statusPlugin.php', {plugin_id, status},{withCredentials: true});
    return data;
};
