import React from "react";
import {Link as RouterLink} from 'react-router-dom';
import {Breadcrumbs} from "@mui/material";
import {Home} from "@mui/icons-material";
import Typography from "@mui/material/Typography";

const BreadCrumbs = ({hrefs, name}) => {
    return (
        <Breadcrumbs separator="›" aria-label="breadcrumbs">
            <RouterLink to="/" style={{display: "flex", alignItems: "center", color: "#00c58a"}}>
                <Home sx={{ mr: 0.5 }} />
                <span>Главная</span>
            </RouterLink>
            {hrefs && hrefs.map((item, i) =>
                !item.href ? (
                    <Typography key={i}>{item.name}</Typography>
                ) : (
                    <RouterLink key={i} to={item.href} style={{display: "flex", alignItems: "center", color: "#00c58a"}}>
                        {item.name}
                    </RouterLink>
                )
            )}
            <Typography>{name}</Typography>
        </Breadcrumbs>
    );
}

export default BreadCrumbs;