import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {createPlugin, indexPlugins, statusPlugin, updatePlugin} from "../http/PluginAPI";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {
    alpha,
    Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, Modal,
    Paper, Select, Stack, Switch, Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    TableSortLabel, TextField
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {AddCircle, Refresh, Settings} from "@mui/icons-material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import BreadCrumbs from "../components/Breadcrumbs";
import {CREATE_SERVER} from "../utils/consts";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

const style = {
    position: 'absolute',
    top: {xs: 'unset', xl: '50%'},
    left: {xs: 0, xl : '50%'},
    right: {xs: 0},
    bottom: {xs: 0},
    transform: {xs: 'none', xl: 'translate(-50%, -50%)'},
    width: {sx: 700},
    maxWidth: {xs: 'unset'},
    bgcolor: 'background.paper',
    border: '2px solid #555',
    boxShadow: 24,
    borderRadius: {xs: 0, xl: '10px'},
    p: 4,
};

function DeleteIcon() {
    return null;
}

function FilterListIcon() {
    return null;
}

const Plugins = observer(() => {
    const [openSettings, setOpenSettings] = React.useState(false);
    const handleOpenSettings = () => setOpenSettings(true);
    const handleCloseSettings = () => setOpenSettings(false);
    const [openAddPlugin, setOpenAddPlugin] = React.useState(false);
    const handleOpenAddPlugin = () => setOpenAddPlugin(true);
    const handleCloseAddPlugin = () => setOpenAddPlugin(false);
    const [plugins, setPlugins] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(9);
    const [ind, setInd] = useState(0);
    const [provider, setProvider] = useState(1);
    const [name, setName] = useState(null);
    const [version, setVersion] = useState(null);
    const [file, setFile] = useState(null);

    const headCells = [
        {
            id: 'plugin_id',
            numeric: true,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Страница',
        },
        {
            id: 'plugin_name',
            numeric: false,
            disablePadding: false,
            label: 'Наименование',
        },
        {
            id: 'version',
            numeric: false,
            disablePadding: false,
            label: 'Версия',
        },
        {
            id: 'download_url',
            numeric: false,
            disablePadding: false,
            label: 'Ссылка',
        },
        {
            id: 'updated_at',
            numeric: false,
            disablePadding: false,
            label: 'Обновлено',
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Статус',
        }
    ];

    useEffect(() => {
        indexPlugins().then(data => {
            console.log(data);
            setPlugins(data);
        }).catch(e => {
            console.log(e);
        });
    }, []);

    useEffect(() => {
        //обновление страницы
    }, [plugins]);

    useEffect(() => {
        //обновление страницы
    }, [ind]);


    const click = async () => {
        try {
            let data;
            const formData = new FormData();
            formData.append('provider', provider);
            formData.append('name', name);
            formData.append('version', version);
            formData.append('file', file);
            data = await createPlugin(formData);
            console.log(data);
        } catch (e) {
            console.log(e.response.data.message);
        }
    }

    const selectStatus = async (status, plugin_id) => {
        const new_plugins = plugins.map(item => {
            if (item.plugin_id === plugin_id) item.status = status;
            return item;
        });

        await statusPlugin(plugin_id, status).then(
            data => {
                console.log('StatusPlugin ' + data);
            }
        ).catch(e => {
            console.log(e);
        });

        setPlugins(new_plugins);

    }

    const updatePlugins = (plugin_id) => {
        updatePlugin(plugin_id).then(data => {
            console.log(data);
        }).catch(e => {
           console.log(e);
        });
    }
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell
                        sortDirection={false}
                    >
                    </TableCell>
                    <TableCell
                        sortDirection={false}
                    >
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    function EnhancedTableToolbar(props) {
        const { numSelected } = props;

        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Плагины
                    </Typography>
                    <IconButton>
                        <AddCircle
                            color="primary"
                            fontSize="large"
                            style={{'cursor': 'pointer'}}
                            onClick={handleOpenAddPlugin}
                        />
                    </IconButton>
            </Toolbar>
        );
    }

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = plugins.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - plugins.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(plugins, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, plugins],
    );



    return(
        <>
            <Container maxWidth="xl">
                <Box sx={{ width: '100%', marginTop: 3 }}>
                    <BreadCrumbs hrefs='' name='Список плагинов' />
                </Box>
                <Box sx={{ width: '100%', marginTop: 3 }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 550 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={plugins.length}
                                />
                                <TableBody>
                                    {plugins && visibleRows.map((row, index) => {
                                        const isItemSelected = isSelected(row.name);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.name}
                                                selected={isItemSelected}
                                            >
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                    align="right"
                                                >
                                                    {row.plugin_id}
                                                </TableCell>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left">{row.plugin_name}</TableCell>
                                                <TableCell align="left">{row.version}</TableCell>
                                                <TableCell align="left">{row.download_url}</TableCell>
                                                <TableCell align="left">{row.updated_at}</TableCell>
                                                <TableCell align="left">
                                                    {row.status ? <Checkbox defaultChecked onChange={() => selectStatus(!row.status, row.plugin_id)} />
                                                        : <Checkbox onChange={() => selectStatus(!row.status, row.plugin_id)} />}
                                                </TableCell>
                                                <TableCell align="center"><Settings
                                                    color="action"
                                                    style={{'cursor': 'pointer'}}
                                                    onClick={handleOpenSettings}/></TableCell>
                                                <TableCell align="center"><Refresh
                                                    color="action"
                                                    style={{'cursor': 'pointer'}}
                                                    onClick={() => updatePlugins(row.plugin_id)}/></TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (dense ? 33 : 53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[9, 18, 36]}
                            labelRowsPerPage={"Записей на странице"}
                            component="div"
                            count={plugins.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            </Container>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openAddPlugin}
                onClose={handleCloseAddPlugin}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openAddPlugin}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Добавить плагин
                        </Typography>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <Box sx={{p: 2}}>
                                <Stack sx={{mt: 2}} spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                    <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                        <InputLabel id="select-provider">Источник</InputLabel>
                                        <Select
                                            labelId="select-provider"
                                            id="provider"
                                            value={provider}
                                            label="Источник"
                                            onChange={e => setProvider(e.target.value)}
                                        >
                                            <MenuItem value={1}>Umod</MenuItem>
                                            <MenuItem value={2}>Oxide-russia.ru</MenuItem>
                                            <MenuItem value={3}>Codefling</MenuItem>
                                            <MenuItem value={4}>Moscow.ovh</MenuItem>
                                            <MenuItem value={5}>Topplugin.ru</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                        <TextField variant="outlined" label="Наименование"
                                                   value={name} onChange={e => setName(e.target.value)} />
                                    </FormControl>
                                </Stack>
                                <Stack sx={{mt: 2}} spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                    <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                        <TextField variant="outlined" label="Версия"
                                                   value={version} onChange={e => setVersion(e.target.value)} />
                                    </FormControl>
                                    <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                        <TextField variant="outlined" label="" type="file"
                                                   onChange={e => setFile(e.target.files[0])} />
                                    </FormControl>
                                </Stack>

                            </Box>
                            <Toolbar
                                sx={{
                                    pl: { sm: 2 },
                                    pr: { xs: 2, sm: 2 },
                                }}
                            >
                                <Button variant="contained"
                                        onClick={click}
                                        color="primary" sx={{width: {xs: '100%', sm: '25ch'}, marginLeft: 'auto'}} type="submit">Сохранить</Button>
                            </Toolbar>
                        </Box>


                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openSettings}
                onClose={handleCloseSettings}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openSettings}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Настройки плагина
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            Поменять настройки плагина
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
});

export default Plugins;