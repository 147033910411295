import BreadCrumbs from "../../components/Breadcrumbs";
import {SERVERS_ROUTE} from "../../utils/consts";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
    Divider,
    FormControl,
    FormHelperText,
    Paper,
    Stack,
    TextField
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import Button from "@mui/material/Button";
import {createServer} from "../../http/ServerAPI";

const CreateServer = () => {

    const [serverName, setServerName]       = useState('');
    const [serverDesc, setServerDesc]       = useState('');
    const [serverSite, setServerSite]       = useState('');
    const [serverLogo, setServerLogo]       = useState('');
    const [serverSize, setServerSize]       = useState(4250);
    const [serverSeed, setServerSeed]       = useState(Math.floor(Math.random() * (99999999-10000000) + 10000000));
    const [serverPlay, setServerPlay]       = useState(100);
    const [serverPort, setServerPort]       = useState(28015);
    const [queryPort, setQueryPort]         = useState(28016);
    const [rconPort, setRconPort]           = useState(28543);
    const [rconPasswd, setRconPasswd]       = useState('');
    const [serverIp, setServerIp]           = useState('');
    const [serverLogin, setServerLogin]     = useState('');
    const [serverPasswd, setServerPasswd]   = useState('');
    const [ftpLogin, setFtpLogin]           = useState('');
    const [ftpPasswd, setFtpPasswd]         = useState('');
    const [oxidePwd, setOxidePwd]           = useState('');

    const hrefs = [
        { href: SERVERS_ROUTE, name: "Список серверов" },
    ];

    const click = async () => {
        try {

            if (serverName && serverSize && serverSeed && serverPlay && serverPort && queryPort && rconPort && rconPasswd && serverIp && serverLogin && serverPasswd && ftpLogin && ftpPasswd) {

                    let data = await createServer(  serverName, serverDesc, serverSite, serverLogo, serverSize, serverSeed,
                                                    serverPlay, serverPort, queryPort, rconPort, rconPasswd,
                                                    serverIp, serverLogin, serverPasswd, ftpLogin, ftpPasswd, oxidePwd);
                    console.log(data);
                    if (data.data.server_id) {
                        //
                    }

            }

        } catch (e) {
            console.log (e.response.data.message);
        }
    }

    function EnhancedTableToolbar() {

        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 2, sm: 2 },
                }}
            >
                <>
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        Добавить новый сервер
                    </Typography>
                    {/*<Button variant="contained" color="primary" sx={{width: '25ch'}} type="submit">Сохранить</Button>*/}
                </>
            </Toolbar>
        );
    }


    return (
        <>
            <Container maxWidth="xl">
                <Box sx={{ width: '100%', marginTop: 3 }}>
                    <BreadCrumbs hrefs={hrefs} name='Добавить новый сервер' />
                </Box>
                <Box sx={{ width: '100%', marginTop: 3 }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <EnhancedTableToolbar/>
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 2 },
                            }}
                        >
                            <Typography
                                sx={{ flex: '1 1 100%', color: '#bbb' }}
                                variant="h6"
                                id="serverInfo"
                                component="div"
                            >
                                Информация о сервере
                            </Typography>
                        </Toolbar>
                        <Divider />
                        <Box sx={{p: 2}}>
                            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                <FormControl sx={{width: {xs: '100%', lg: '100%'}}}>
                                    <TextField variant="outlined" label="Название сервера"
                                               value={serverName}
                                               onChange={e => setServerName(e.target.value)}
                                               placeholder="Название и описание сервера" />
                                    <FormHelperText>Рекомендуется до 50 символов ({serverName.length} из 50)</FormHelperText>
                                </FormControl>
                                <FormControl sx={{width: {xs: '100%', lg: '100%'}}}>
                                    <TextField variant="outlined" label="Описание сервера"
                                               value={serverDesc}
                                               onChange={e => setServerDesc(e.target.value)}
                                               placeholder="Описание сервера, для привлечения посетителей" multiline rows={4} />
                                    <FormHelperText>Всё зависит от вашей фантазии</FormHelperText>
                                </FormControl>
                            </Stack>
                            <Stack sx={{mt: 2}} spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                    <TextField variant="outlined" label="Адрес вашего сайта"
                                               value={serverSite}
                                               onChange={e => setServerSite(e.target.value)}
                                               placeholder="Ссылка на сайт (магазин, группу в Дискорде / Вконтакте)" />
                                    <FormHelperText>Не обязательно для заполнения</FormHelperText>
                                </FormControl>
                                <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                    <TextField variant="outlined" label="Ссылка на баннер (логотип)"
                                               value={serverLogo}
                                               onChange={e => setServerLogo(e.target.value)}
                                               placeholder="Рекомендуемый формат jpg, размер 500x250 px" />
                                    <FormHelperText>Не обязательно для заполнения</FormHelperText>
                                </FormControl>
                            </Stack>
                            <Stack sx={{mt: 2}} spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                    <TextField variant="outlined" label="Размер карты"
                                               value={serverSize}
                                               onChange={e => setServerSize(e.target.value)}
                                               placeholder="Укажите размер карты" />
                                    <FormHelperText>Рекомендуемый размер карты 4250</FormHelperText>
                                </FormControl>
                                <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                    <TextField variant="outlined" label="Зерно генерации карты"
                                               value={serverSeed}
                                               onChange={e => setServerSeed(e.target.value)}
                                               placeholder="Случайное число" />
                                    <FormHelperText>Рекомендуется число от 8 знаков</FormHelperText>
                                </FormControl>
                                <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                    <TextField variant="outlined" label="Количество игроков"
                                               value={serverPlay}
                                               onChange={e => setServerPlay(e.target.value)}
                                               placeholder="Укажите максимальное количество игроков" />
                                    <FormHelperText>Рекомендуемое количество 100</FormHelperText>
                                </FormControl>
                            </Stack>
                            <Stack sx={{mt: 2}} spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                    <TextField variant="outlined" label="Порт сервера"
                                               value={serverPort}
                                               onChange={e => setServerPort(e.target.value)}
                                               placeholder="Порт для подключения к игре" />
                                    <FormHelperText>Стандартный порт 28015</FormHelperText>
                                </FormControl>
                                <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                    <TextField variant="outlined" label="Query порт"
                                               value={queryPort}
                                               onChange={e => setQueryPort(e.target.value)}
                                               placeholder="Порт для обмена данными о сервере" />
                                    <FormHelperText>Рекомендуется +1 от игрового, 28016</FormHelperText>
                                </FormControl>
                            </Stack>
                            <Stack sx={{mt: 2}} spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                    <TextField variant="outlined" label="RCON порт"
                                               value={rconPort}
                                               onChange={e => setRconPort(e.target.value)}
                                               placeholder="Порт для администрирования сервера" />
                                    <FormHelperText>Например 28543</FormHelperText>
                                </FormControl>
                                <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                    <TextField variant="outlined" label="RCON пароль"
                                               value={rconPasswd}
                                               onChange={e => setRconPasswd(e.target.value)}
                                               placeholder="Пароль для администрирования сервера" />
                                    <FormHelperText>Пароль для администрирования</FormHelperText>
                                </FormControl>
                            </Stack>
                        </Box>
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 2 },
                            }}
                        >
                            <Typography
                                sx={{ flex: '1 1 100%', color: '#bbb' }}
                                variant="h6"
                                id="serverInfo"
                                component="div"
                            >
                                Параметры сервера
                            </Typography>
                        </Toolbar>
                        <Divider />
                        <Box sx={{p: 2}}>
                            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                    <TextField variant="outlined" label="IP сервера"
                                               value={serverIp}
                                               onChange={e => setServerIp(e.target.value)}
                                               placeholder="IP адрес сервера" />
                                    <FormHelperText>Адрес для подключения к серверу</FormHelperText>
                                </FormControl>
                                <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                    <TextField variant="outlined" label="Логин"
                                               value={serverLogin}
                                               onChange={e => {
                                                   setServerLogin(e.target.value);
                                                   setFtpLogin(e.target.value);
                                                   setOxidePwd('/home/' + e.target.value + '/server/oxide')
                                               }}
                                               placeholder="Логин пользователя" />
                                    <FormHelperText>Например rust-server</FormHelperText>
                                </FormControl>
                                <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                    <TextField variant="outlined" label="Пароль"
                                               value={serverPasswd}
                                               onChange={e => {
                                                   setServerPasswd(e.target.value);
                                                   setFtpPasswd(e.target.value);
                                               }}
                                               placeholder="Пароль пользователя" />
                                    <FormHelperText>Рекомендуются латинские буквы и цифры</FormHelperText>
                                </FormControl>
                            </Stack>
                        </Box>
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 1, sm: 2 },
                            }}
                        >
                            <Typography
                                sx={{ flex: '1 1 100%', color: '#bbb' }}
                                variant="h6"
                                id="serverInfo"
                                component="div"
                            >
                                Параметры FTP
                            </Typography>
                        </Toolbar>
                        <Divider />
                        <Box sx={{p: 2}}>
                            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                    <TextField variant="outlined" label="Логин FTP"
                                               value={ftpLogin}
                                               onChange={e => setFtpLogin(e.target.value)}
                                               placeholder="Логин для FTP пользователя" />
                                    <FormHelperText>Может совпадать с пользователем сервера</FormHelperText>
                                </FormControl>
                                <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                    <TextField variant="outlined" label="Пароль FTP"
                                               value={ftpPasswd}
                                               onChange={e => setFtpPasswd(e.target.value)}
                                               placeholder="Пароль FTP пользователя" />
                                    <FormHelperText>Может совпадать с пользователем сервера</FormHelperText>
                                </FormControl>
                                <FormControl sx={{width: {xs: '100%'}, flex: {lg: 1}}}>
                                    <TextField variant="outlined" label="Путь до Oxide"
                                               value={oxidePwd}
                                               onChange={e => setOxidePwd(e.target.value)}
                                               placeholder="Полный путь до папки oxide" />
                                    <FormHelperText>Например /home/rust/server/oxide</FormHelperText>
                                </FormControl>
                            </Stack>
                        </Box>
                        <Toolbar
                            sx={{
                                pl: { sm: 2 },
                                pr: { xs: 2, sm: 2 },
                            }}
                        >
                            <Button variant="contained"
                                    onClick={click}
                                    color="primary" sx={{width: {xs: '100%', sm: '25ch'}, marginLeft: 'auto'}} type="submit">Сохранить</Button>
                        </Toolbar>

                    </Paper>
                </Box>
            </Container>
        </>
    );
}

export default CreateServer;