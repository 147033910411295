import React, {useContext, useState, useEffect} from "react";
import {Context} from "..";
import {observer} from "mobx-react-lite";
import Container from "@mui/material/Container";

const Home = observer(() => {
    const {user} = useContext(Context);
    const [name, setName] = useState('');
    const [role, setRole] = useState('');

    useEffect(() => {
        if(user.userName){
            setName(user.userName);
            setRole(user.role);
        }else{
            setName('');
            setRole('');
        }
    }, [user.userName]);


    return (
        <>
            <Container maxWidth="xl">
                <h1>Home</h1>
                <div>Имя: {name}</div>
                <div>Роль: {role}</div>
            </Container>
        </>

    );
});

export default Home;