import React, {useContext, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {createServer, indexServers, jobServer, syncServer} from "../http/ServerAPI";
import {useNavigate} from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {
    alpha,
    Checkbox, CircularProgress, createStyles, FormControlLabel, makeStyles, Modal,
    Paper, Switch, Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    TableSortLabel, Tabs, TextField
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {
    AddCircle,
    Lan,
    PermMedia,
    PlayArrow,
    PlusOne,
    Refresh, RocketLaunch,
    Settings,
    Source,
    Stop, TipsAndUpdates,
    Warning
} from "@mui/icons-material";
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import {HOME_ROUTE, SERVER_PLUGINS_ROUTE, CREATE_SERVER} from "../utils/consts";
import {Context} from "../index";
import Button from "@mui/material/Button";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import BreadCrumbs from "../components/Breadcrumbs";


const style = {
    position: 'absolute',
    top: {xs: 'unset', xl: '50%'},
    left: {xs: 0, xl : '50%'},
    right: {xs: 0},
    bottom: {xs: 0},
    transform: {xs: 'none', xl: 'translate(-50%, -50%)'},
    width: {sx: 700},
    maxWidth: {xs: 'unset'},
    bgcolor: 'background.paper',
    border: '2px solid #555',
    boxShadow: 24,
    borderRadius: {xs: 0, xl: '10px'},
    p: 4,
};


function DeleteIcon() {
    return null;
}

function FilterListIcon() {
    return null;
}

const Servers = observer(() => {
    const navigate = useNavigate();
    const {user} = useContext(Context);
    const [server_name,setServer_name] = useState(null);
    const [server_selected, setServer_selected] = useState(null);
    const [server_url,setServer_url] = useState(null);
    const [description,setDescription] = useState(null);
    const [ftp_address,setFtp_address] = useState(null);
    const [ftp_login,setFtp_login] = useState(null);
    const [ftp_password,setFtp_password] = useState(null);
    const [server_login,setServer_login] = useState(null);
    const [server_password,setServer_password] = useState(null);
    const [oxide_folder,setOxide_folder] = useState(null);
    const [openConfigs, setOpenConfigs] = React.useState(false);
    const handleOpenConfigs = () => setOpenConfigs(true);
    const handleCloseConfigs = () => setOpenConfigs(false);
    const [openServerStop, setOpenServerStop] = React.useState(false);
    const handleOpenServerStop = () => setOpenServerStop(true);
    const handleCloseServerStop = () => setOpenServerStop(false);
    const [stop, setStop] = useState(0);
    const [openServerStart, setOpenServerStart] = React.useState(false);
    const handleOpenServerStart = () => setOpenServerStart(true);
    const handleCloseServerStart = () => setOpenServerStart(false);
    const [start, setStart] = useState(0);
    const [openServerUpdate, setOpenServerUpdate] = React.useState(false);
    const handleOpenServerUpdate = () => setOpenServerUpdate(true);
    const handleCloseServerUpdate = () => setOpenServerUpdate(false);
    const [update, setUpdate] = useState(0);
    const [openAddServer, setOpenAddServer] = React.useState(false);
    const handleOpenAddServer = () => setOpenAddServer(true);
    const handleCloseAddServer = () => setOpenAddServer(false);
    const [openSettings, setOpenSettings] = React.useState(false);

    const handleCloseSettings = () => setOpenSettings(false);
    const [servers, setServers] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(9);
    const [ind, setInd] = useState(0);
    const [value, setValue] = React.useState('1');
    const [spinners, setSpinners] = useState([]);
    let timer;
    const headCells = [
        {
            id: 'server_id',
            numeric: true,
            disablePadding: true,
            label: '#',
        },
        {
            id: 'title',
            numeric: false,
            disablePadding: false,
            label: 'Название',
        },
        {
            id: 'status_title',
            numeric: false,
            disablePadding: false,
            label: 'Статус',
        },
        {
            id: 'version',
            numeric: false,
            disablePadding: false,
            label: 'Версия',
        }
    ];

    useEffect(() => {
        indexServers().then(data => {
            console.log(data);
            setServers(data);
        }).catch(e => {
            console.log(e);
        });
    }, [user]);

    useEffect(() => {
        //обновление страницы
        let reload = null;
        servers.map(item => {
            if (item.status_id === 5) {
                reload = true;
            }
        });
        if (reload) {
            timer = setTimeout(() => {
                indexServers().then(data => {
                    console.log(data);
                    setServers(data);
                }).catch(e => {
                    console.log(e);
                });
            }, 30000);
        }
        return () => clearTimeout(timer);
    }, [servers]);

    useEffect(() => {
        //обновление страницы
    }, [ind]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleOpenSettings = (server_id) => {
        setServer_selected(server_id);
        setOpenSettings(true);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell
                        sortDirection={false}
                    >
                    </TableCell>
                    <TableCell
                        sortDirection={false}
                    >
                    </TableCell>
                    <TableCell
                        sortDirection={false}
                    >
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    const sync = async (server_id) => {
        try {
            let data = await syncServer(server_id);
            console.log(data);
            if (data.data.server_id) {
                indexServers().then(save => {
                    console.log(save);
                    setServers(save);
                }).catch(e => {
                    console.log(e);
                });
            }
            handleCloseSettings();

        } catch (e) {
            console.log (e.response.data.message);
        }
    }

    const addServer = async () => {
        try {
            let data = await createServer(server_name, server_url);
            console.log(data);
            if (data.data.server_id) {
                indexServers().then(save => {
                    console.log(save);
                    setServers(save);
                }).catch(e => {
                    console.log(e);
                });
            }
            handleCloseAddServer();

        } catch (e) {
            console.log (e.response.data.message);
        }
    }

    function EnhancedTableToolbar() {

        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }}
            >
                    <>
                        <Typography
                            sx={{ flex: '1 1 100%' }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            Сервера
                        </Typography>
                        <IconButton>
                            <AddCircle
                                color="primary"
                                fontSize="large"
                                style={{'cursor': 'pointer'}}
                                onClick={() => navigate(CREATE_SERVER)}
                            />
                        </IconButton>
                    </>
            </Toolbar>
        );
    }

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = servers.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const timeoutService = (server_id) => {
        setTimeout(() => {
            setServers(servers.map(item => {
                if (item.server_id === server_id) {
                    item.spinner = false;
                    if (item.status_id === 3) {
                        item.status_id = 4;
                        item.status = 'отключен';
                    } else if (item.status_id === 4) {
                        item.status_id = 3;
                        item.status = 'подключен';
                    }
                }
                return item;
            }));
        }, 3000);
    }

    const serverJob = async (server_id, job) => {
        try {
            let data = await jobServer(server_id, job);
            console.log(data);
        } catch (e) {
            console.log (e.response.data.message);
        }
    }

    const serverStart = (server_id) => {
        setServers(servers.map(item => {
            if (item.server_id === server_id && !item.spinner && item.status_id === 4) {
                item.spinner = true;
                handleCloseServerStart();
                serverJob(server_id, 'start').then(r => {
                    console.log(r);
                    setServers(servers.map(item => {
                        if (item.server_id === server_id) {
                            item.spinner = false;
                            if (item.status_id === 4) {
                                item.status_id = 5;
                                item.status = 'запуск';
                            }
                        }
                        return item;
                    }));
                });
            }
            return item;
        }));
    }

    const serverStop = (server_id) => {
        setServers(servers.map(item => {
            if (item.server_id === server_id && !item.spinner && item.status_id === 3) {
                item.spinner = true;
                handleCloseServerStop();
                serverJob(server_id, 'stop').then(r => {
                    console.log(r);
                    setServers(servers.map(item => {
                        if (item.server_id === server_id) {
                            item.spinner = false;
                            if (item.status_id === 3) {
                                item.status_id = 4;
                                item.status = 'отключен';
                            }
                        }
                        return item;
                    }));
                });
            }
            return item;
        }));
    }

    const serverUpdate = (server_id) => {
        setServers(servers.map(item => {
            if (item.server_id === server_id && !item.spinner && item.status_id === 4) {
                item.spinner = true;
                handleCloseServerUpdate();
                serverJob(server_id, 'update').then(r => {
                    console.log(r);
                    setServers(servers.map(item => {
                        if (item.server_id === server_id) {
                            item.spinner = false;
                            if (item.status_id === 4) {
                                item.status_id = 2;
                                item.status = 'настраивается';
                            }
                        }
                        return item;
                    }));
                });
            }
            return item;
        }));
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - servers.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(servers, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, servers],
    );

    return(
        <>
            <Container maxWidth="xl">
                <Box sx={{ width: '100%', marginTop: 3 }}>
                    <BreadCrumbs hrefs='' name='Список серверов' />
                </Box>
                <Box sx={{ width: '100%', marginTop: 3 }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <EnhancedTableToolbar numSelected={selected.length} />
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 550 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={servers.length}
                                />
                                <TableBody>
                                    {servers && visibleRows.map((row, index) => {
                                        const isItemSelected = isSelected(row.title);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.title}
                                                selected={isItemSelected}
                                            >
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                    align="right"
                                                >
                                                    {row.server_id}
                                                </TableCell>
                                                <TableCell align="left">{row.title} {row.online}/100</TableCell>
                                                <TableCell align="left">
                                                        <Box sx={{display: "flex", alignItems: "center"}}>
                                                            {row.status_id === 1 ? <Lan sx={{color: '#ff7272', mr: 0.5}}/> : null}
                                                            {row.status_id === 2 ? <Refresh sx={{color: '#be5dc9', mr: 0.5}} /> : null}
                                                            {row.status_id === 5 ? <RocketLaunch sx={{color: '#5daec9', mr: 0.5}} /> : null}
                                                            {row.status_id === 3 || row.status_id === 4 ?
                                                                <>
                                                                    <Tooltip title="Остановить" placement="bottom-start">
                                                                        <Stop sx={{color: row.status_id === 4 ? '#ff7272' : '#ffdfdf', mr: 0.1}}
                                                                              onClick={() => {
                                                                                  setStop(row.server_id);
                                                                                  handleOpenServerStop();
                                                                              }} />
                                                                    </Tooltip>
                                                                    <Tooltip title="Запустить" placement="bottom-start">
                                                                        <PlayArrow sx={{color: row.status_id === 3 ? '#9aff72' : '#e1ffd0', mr: 0.5}}
                                                                                   onClick={() => {
                                                                                       setStart(row.server_id);
                                                                                       handleOpenServerStart();
                                                                                   }} />
                                                                    </Tooltip>
                                                                </> : null}
                                                            {row.spinner ?
                                                                <Box sx={{ display: 'flex', mr: 1 }}>
                                                                    <CircularProgress size="1rem" />
                                                                </Box>
                                                            : null}
                                                            <Tooltip title={row.description} placement="bottom-start">
                                                                <span>{row.status}</span>
                                                            </Tooltip>

                                                        </Box>
                                                </TableCell>
                                                <TableCell align="left">
                                                        <Box sx={{display: "flex", alignItems: "center"}}>
                                                            {row.status_id === 4 ?
                                                                <>
                                                                    <Tooltip title="Обновить" placement="bottom-start">
                                                                        <TipsAndUpdates sx={{color: '#fff5cb', mr: 0.5}}
                                                                                        onClick={() => {
                                                                                            setUpdate(row.server_id);
                                                                                            handleOpenServerUpdate();
                                                                                        }}/>
                                                                    </Tooltip>
                                                                </>
                                                                : null}
                                                            {row.required !== row.installed ?
                                                                <Tooltip title={'Актуальная версия ' + row.required} placement="bottom-start">
                                                                    <Warning sx={{color: '#ffce72', mr: 0.5}}/>
                                                                </Tooltip>: null}<span>{row.installed}</span>
                                                        </Box>

                                                </TableCell>
                                                <TableCell align="center"><PermMedia
                                                                            color="primary"
                                                                            style={{'cursor': 'pointer'}}
                                                                            onClick={() => navigate(SERVER_PLUGINS_ROUTE + '/' + row.server_id)}/></TableCell>
                                                <TableCell align="center"><Source
                                                                            color="primary"
                                                                            style={{'cursor': 'pointer'}}
                                                                            onClick={handleOpenConfigs}/></TableCell>
                                                <TableCell align="center"><Settings
                                                                            color="action"
                                                                            style={{'cursor': 'pointer'}}
                                                                            onClick={() => handleOpenSettings(row.server_id)}/></TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (dense ? 33 : 53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[9, 18, 36]}
                            labelRowsPerPage={"Записей на странице"}
                            component="div"
                            count={servers.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            </Container>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openAddServer}
                onClose={handleCloseAddServer}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openAddServer}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Добавить новый сервер
                        </Typography>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleTabChange} aria-label="Добавить новый сервер">
                                        <Tab label="Название" value="1" />
                                        <Tab label="Параметры FTP" value="2" />
                                        <Tab label="Параметры Сервера" value="3" />
                                        <Tab label="Настройки игры" value="4" />
                                    </TabList>
                                </Box>

                                <TabPanel value="2">
                                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                        <Box
                                            component="form"
                                            sx={{
                                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <Box sx={{width: '25ch'}}>
                                                <TextField
                                                    id="ftp_address"
                                                    label="Адрес FTP сервера"
                                                    variant="outlined"
                                                    value={ftp_address}
                                                    onChange={e => setFtp_address(e.target.value)}
                                                    size="small" fullWidth  />
                                            </Box>
                                            <Box sx={{width: '25ch'}}>
                                                <TextField
                                                    id="oxide_folder"
                                                    label="Полный путь до папки oxide"
                                                    variant="outlined"
                                                    value={oxide_folder}
                                                    onChange={e => setOxide_folder(e.target.value)}
                                                    size="small" fullWidth  />
                                            </Box>
                                            <Box sx={{width: '25ch'}}>
                                                <TextField
                                                    id="ftp_login"
                                                    label="Логин FTP"
                                                    variant="outlined"
                                                    value={ftp_login}
                                                    onChange={e => setFtp_login(e.target.value)}
                                                    size="small" fullWidth  />
                                            </Box>
                                            <Box sx={{width: '25ch'}}>
                                                <TextField
                                                    id="ftp_password"
                                                    label="Пароль FTP"
                                                    variant="outlined"
                                                    value={ftp_password}
                                                    onChange={e => setFtp_password(e.target.value)}
                                                    size="small" fullWidth  />
                                            </Box>
                                        </Box>
                                    </Typography>
                                </TabPanel>

                                <TabPanel value="4">

                                    <Box sx={{width: '50ch'}}>
                                        <TextField
                                            id="rcon_password"
                                            label="Пароль RCON"
                                            variant="outlined"
                                            value={server_password}
                                            onChange={e => setServer_password(e.target.value)}
                                            size="small"  />
                                    </Box>
                                    <Box sx={{width: '50ch'}}>
                                        <TextField
                                            id="taskset"
                                            label="Список ядер"
                                            variant="outlined"
                                            value={server_password}
                                            onChange={e => setServer_password(e.target.value)}
                                            size="small"  />
                                    </Box>
                                    <Box sx={{width: '25ch'}}>
                                        <Button
                                            sx={{m: 1}}
                                            color="primary"
                                            variant="outlined"
                                            onClick={addServer}
                                            fullWidth >Добавить</Button>
                                    </Box>
                                </TabPanel>
                            </TabContext>
                        </Box>


                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openConfigs}
                onClose={handleCloseConfigs}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openConfigs}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Конфиги плагинов на сервере
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            Список конфигов с возможностью редактирования, удаления
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openServerStop}
                onClose={handleCloseServerStop}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openServerStop}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Остановка сервера
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            Действительно остановить сервер? {stop ? <Button variant="contained" color="warning" onClick={()=>serverStop(stop)}>Остановить</Button> : null}
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openServerStart}
                onClose={handleCloseServerStart}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openServerStart}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Запуск сервера
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            Действительно запустить сервер? {start ? <Button variant="contained" color="primary" onClick={()=>serverStart(start)}>Запустить</Button> : null}
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openServerUpdate}
                onClose={handleCloseServerUpdate}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openServerUpdate}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Обновление сервера
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            Действительно обновить сервер? {update ? <Button variant="contained" color="warning" onClick={()=>serverUpdate(update)}>Обновить</Button> : null}
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openSettings}
                onClose={handleCloseSettings}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openSettings}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Параметры сервера
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            {server_selected && servers && servers.filter((item) => item.server_id === server_selected)[0].status_id === 1 ?
                                <>Не произведена синхронизация с сервером.<br/>Загрузите файл на сервер, после этого нажмите <Button variant="contained" color="primary" onClick={()=>sync(server_selected)}>Синхронизировать</Button><br/>Синхронизация займет несколько минут</>
                                : null}
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
});

export default Servers;