import React, {useContext, useState} from "react";
import {registration} from "../http/userAPI";
import {HOME_ROUTE} from "../utils/consts";
import {Context} from "..";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";

const Registration = observer(() => {
    const {user} = useContext(Context);
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const click = async () => {
        try {
            let data;
            data = await registration(name, username, password);
            user.setUser(data);
            navigate(HOME_ROUTE);
        } catch (e) {
            console.log(e.response.data.message);
        }

    };

    return(
        <div>
            <div>Регистрация</div>
            <div>
                <input type="text" onChange={e => setName(e.target.value)} placeholder="Введите Имя..."/>
            </div>
            <div>
                <input type="text" onChange={e => setUsername(e.target.value)} placeholder="Введите Логин..."/>
            </div>
            <div>
                <input type="password" onChange={e => setPassword(e.target.value)} placeholder="Введите Пароль..."/>
            </div>
            <div>
                <button onClick={click}>Зарегистрироваться</button>
            </div>
        </div>
    );
});

export default Registration;