import React, {useContext, useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {authRoutes, publicRoutes} from "../routes";
import {Context} from "..";

const AppRouter = () => {
    const {user} = useContext(Context);
    const location = useLocation();
    useEffect(()=>{
        //page update && Math.ceil((Date.now()) / 1000) >= jwt_decode(user.user.access_token).exp
    },[location.pathname]);

    return (
        <Routes>
            {user.isAuth && authRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={<Component/>}/>
            )}
            {publicRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={<Component/>}/>
            )}
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};

export default AppRouter;