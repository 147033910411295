import React, {useContext, useState} from "react";
import {login} from "../http/userAPI";
import {useNavigate} from "react-router-dom";
import {HOME_ROUTE} from "../utils/consts";
import {Context} from "..";
import {observer} from "mobx-react-lite";

const Login = observer(() => {
    const {user} = useContext(Context);
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const click = async () => {
        try {
            let data;
            data = await login(username, password);
            user.setUser(data);
            navigate(HOME_ROUTE);
        } catch (e) {
            console.log(e.response.data.message);
        }

    };

    return (
        <div>
            <div>Авторизация</div>
            <div>
                <input type="text" onChange={e => setUsername(e.target.value)} placeholder="Введите Логин..."/>
            </div>
            <div>
                <input type="password" onChange={e => setPassword(e.target.value)} placeholder="Введите Пароль..."/>
            </div>
            <div>
                <button onClick={click}>Войти</button>
            </div>
        </div>
    );

});

export default Login;